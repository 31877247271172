<template src ="./recipient.html"></template>
<style scoped src ="./recipient.css"></style>
<script>
import accountConstants from '@/constants/AccountConstants.vue'
import { mapActions, mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import { required, email, requiredIf } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants.vue'
import Utils from '@/utils/utils'
import accountService from '@/services/AccountService.vue'
import pagination from '@/components/vtable/vtpagination.vue'

export default {
    data () {
        return {     
            useremail : '',
            country : '',            
            countryList : accountConstants.countryList,       
            errorMessages: applicationConstants.formsMessages,
            loaderVisible : false,
            columns : ['Id','Name','MobileNumber','RemittanceGateway','RemittanceCurrency','Action'],
            options : {
                headings : {
                    Id : 'Recipient Id',
                    PhoneNumber : 'Phone No',
                    RemittanceGateway : 'Remittance Gateway',
                    RemittanceCurrency : 'Currency'
                }
            },
            RecipientItems : [],
            Utils : Utils,
            heading: '',
            isLazyLoadingEnabled: applicationConstants.enableLazyLoading
        }
    },
    props : ['showAddRecipients'],
    validations : {
        useremail : {
            required,
            email
        },
        country : {
            required: requiredIf(function (){
                return !this.showAddRecipients
            })
        }
    },
    components : {
        Loading, pagination
    },
    methods : {
        ...mapActions(['fetchRecipientDetails','fetchRemittanceOption','reserveUseremail']),
        async getRecipients () {  
            try {
                this.$v.useremail.$touch()
                this.$v.country.$touch()            
                if(this.$v.$invalid) {
                    return
                }
                this.reserveUseremail(this.useremail)
                this.loaderVisible = true
                await this.fetchRecipientDetails({'CustomerEmail':this.useremail, 'Country': this.country})
                let remittanceOptionListIds = []
                if(this.getRemittanceOptions2) this.getRemittanceOptions2.forEach(rO => remittanceOptionListIds.push(rO.RemittanceOptionId))
                this.RecipientItems = this.getRecipientDetails.filter(recipient => remittanceOptionListIds.includes(recipient.RemittanceGatewayId))
                if(this.isLazyLoadingEnabled) {
                    this.$refs.paginationRef.selectedPage = 1
                }
                this.loaderVisible = false  
            } catch (error) {
                this.loaderVisible = false
                this.$parent.alertObject = error
                if (this.$parent.$refs.childModal) {
                    this.$bvModal.show(this.$parent.$refs.childModal.id)
                }
            }                         
        },
        editRecipientDetails(id){
            this.$bvModal.msgBoxConfirm('Are you sure you want to pick for Recipient Id: '+ id +' ')
            .then( async value => {
                if (value) {
                    let recipient = this.getRecipientDetails.find(s=> s.Id === id)
                    this.$emit('recipientData',{...recipient,'email' : this.useremail})
                }               
            })
        },
        addRecipient() {
            this.$v.useremail.$touch()
            this.$v.country.$touch()            
            if(this.$v.$invalid) {
                return
            }
            this.$emit('addRecipientData',this.useremail,this.country)
        },
        async deleteRecipient(Id) {
            try {
                this.$bvModal.msgBoxConfirm('Are you sure you want to delete Recipient:'+ Id)
                .then(async result => {
                    if(result) {
                        this.loaderVisible = true
                        let recipient = this.getRecipientDetails.find(s=> s.Id === Id)
                        if(recipient) {
                            Object.keys(recipient).map(key=>recipient[key] = recipient[key] ? recipient[key] : null)
                            await accountService.methods.updateRecipientDetails({
                                'OperationType': 'D',
                                'Recipient': recipient,
                                'CustomerEmail': this.useremail
                            })
                            .then(() => {
                                this.$parent.showAlert(1, 'Recipient Deleted Successfully')
                                this.RecipientItems = this.RecipientItems.filter(item => item.Id !== Id)
                            }).catch(error => {
                                this.$parent.alertObject = error
                                if (this.$parent.$refs.childModal) {
                                    this.$bvModal.show(this.$parent.$refs.childModal.id)
                                }
                            })
                        }
                        this.loaderVisible = false
                    }    
                })
 
            } catch (error) {
                this.loaderVisible = false
                this.$parent.alertObject = error
                if (this.$parent.$refs.childModal) {
                    this.$bvModal.show(this.$parent.$refs.childModal.id)
                }
            }
        }
    },
    computed : {
        ...mapGetters(['getRecipientDetails','getRemittanceOptions2','getUseremail']),
        recipientDataTable() {
            let recipientsData = []
            if(this.RecipientItems && this.RecipientItems.length > 0) {
                recipientsData = this.RecipientItems
                if(this.isLazyLoadingEnabled) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    recipientsData = recipientsData.slice((selectedPage - 1)* 10, selectedPage * 10)
                }
                recipientsData.forEach(item => {
                    let remittanceGateway = this.getRemittanceOptions2.find(s=> s.RemittanceOptionId === item.RemittanceGatewayId) 
                    item['Name'] = item.FirstName + ' ' + (item.LastName ? item.LastName : '')
                    item['RemittanceGateway'] = remittanceGateway?.Description
                })
            }
            const updateTotalRecords = () => {
                if(this.$refs.paginationRef) {
                    this.$refs.paginationRef.totalRecords = this.RecipientItems?.length ?? 0
                }
            }
            if(this.isLazyLoadingEnabled) updateTotalRecords()
            return recipientsData
        }
    },
    async created () {
        if (this.getRemittanceOptions2 === null || this.getRemittanceOptions2 == undefined) {
            this.loaderVisible = true
            await this.fetchRemittanceOption()
            this.loaderVisible = false
        }
    },
    mounted () {
        this.RecipientItems = []
        switch(this.$route.name){
            case 'ETransfer':
                this.heading = 'Initiate E-Transfer'
                break;
            case 'SendMoney':
                this.heading = 'Send Money'
                break;
            case 'InitiateEFT':
                this.heading = 'Initiate EFT'
                break;
            case 'AmendRecipient':
                this.heading = 'Amend Recipients'
                break;  
        }
    }
}
</script>
